<script lang="ts" setup>
import {
  AccordionFieldsFragment,
  AccordionItemFieldsFragment,
  FieldParagraphAccordionItemFieldDownloadItems,
  FileFieldsFragment,
  MediaDocument,
  MediaImageFieldsFragment,
} from '~/api/graphql/generated'

const props = defineProps<{
  entity: AccordionFieldsFragment
}>()

const getType = (type: string) => {
  switch (type) {
    case 'deep_blue':
      return { primary: true, slide: true }
    case 'light_blue':
      return { secondary: true, slide: true }
    default:
      return {}
  }
}

const getDownloads = (items: FieldParagraphAccordionItemFieldDownloadItems[]) => {
  return items.map((item) => {
    const entity = item?.entity as MediaDocument
    const document = entity.fieldMediaDocument?.entity as FileFieldsFragment

    const title = entity.name || document.filename || ''
    const type = document.filemime || ''
    const size = document.filesize || ''
    const url = document.url || ''

    return {
      title,
      type,
      size,
      url,
    }
  })
}

const items = props.entity.fieldItems?.map((item) => {
  const data = item?.entity as AccordionItemFieldsFragment

  return {
    title: data.fieldTitle || '',
    description: data.fieldText?.processed,
    image: data.fieldImage?.entity as MediaImageFieldsFragment,
    downloads: getDownloads(data.fieldDownloadItems?.filter(Boolean) || []),
    link: {
      urlType: data.fieldLink?.url?.__typename,
      url: data.fieldLink?.url?.path,
      title: data.fieldLink?.title,
      type: getType(data.fieldLinkType || ''),
      target: data.fieldLink?.options.attributes.target,
    },
  }
})
</script>

<template>
  <Section>
    <div class="t-10 t-o-1 tl-7 tl-o-0 ds-o-1">
      <h2>{{ entity.fieldTitle }}</h2>
      <Accordion>
        <AccordionItem
          v-for="(item, index) in items"
          :key="index"
          v-animate="{ animation: 'vertical-reveal' }"
          :index="index"
          :title="item.title"
        >
          <Image v-if="item.image" :entity="item.image" type="teaser" />
          <TextSpecial v-if="item.description" class="content" :text="item.description" />
          <Downloads :items="item.downloads" />

          <Button v-if="item.link.url" v-bind="item.link.type" :to="item.link.url" :target="item.link.target">
            {{ item.link.title ?? $t('Read more') }}
          </Button>
        </AccordionItem>
      </Accordion>
    </div>
  </Section>
</template>

<style lang="scss" scoped>
.downloads {
  > :deep(li:last-child .item) {
    border-bottom: none;
  }
  &:not(:last-child) {
    margin-bottom: var(--p-mb);
  }
}
</style>
